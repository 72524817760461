<template>
  <div class="body1">
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link :to="{name: 'home'}">{{ $t('home') }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ $t('login') }}</a-breadcrumb-item>
    </a-breadcrumb>

    <a-row type="flex" justify="space-around" align="middle" :style="{ margin:'80px auto'}">
      <a-col :span="16" :style="{ textAlign:'center',marginBottom: '30px'}">
        <a-typography-title :level="4">{{ $t('login') }}</a-typography-title>
        <a-typography-text><router-link :to="{name: 'register'}">{{ $t('no_account_register') }}</router-link></a-typography-text>
      </a-col>
      <a-col :span="10">
        <a-form name="login_form" class="login-form" :model="loginForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" layout="horizontal" :colon="false" :hideRequiredMark="true" @finish="onLogin">
          <a-form-item name="username" :label="$t('username')" :rules="[{ required: true, message: $t('input_username') }]">
            <a-input :placeholder="$t('username')" v-model:value="loginForm.username">
              <template #prefix>
                <UserOutlined/>
              </template>
            </a-input>
          </a-form-item>

          <a-form-item name="password" :label="$t('password')"  :rules="[{ required: true, message: $t('input_password') }]">
            <a-input-password :placeholder="$t('password')" v-model:value="loginForm.password">
              <template #prefix>
                <LockOutlined/>
              </template>
            </a-input-password>
          </a-form-item>

          <a-alert type="error" :closable="false" v-if="loginForm.error" :message="loginForm.error" showIcon/>

          <a-form-item style="margin-bottom: 0;" :wrapper-col="{ offset: 4, span: 20 }">
            <a-button type="primary" html-type="submit" block :loading="loginLoading" :disabled="loginForm.username === '' || loginForm.password === ''">
              {{ $t('login') }}
            </a-button>
          </a-form-item>

          <a-row>
            <a-col :span="20" :offset="4">
              <div class="login-form-wrap">
                <a-form-item name="remember" no-style>
                  <a-checkbox v-model:checked="loginForm.remember">{{ $t('remember_me') }}</a-checkbox>
                </a-form-item>
                <router-link :to="{name: 'find-password'}">{{ $t('forgot_password') }}</router-link>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {
  UserOutlined,
  LockOutlined,
} from '@ant-design/icons-vue';
import {login} from "@/services/account";
import {setAuthorization} from "@/utils/request";
import {isEmpty} from "@/utils/util";

export default {
  name: "Login",
  components: {
    UserOutlined,
    LockOutlined
  },
  data(){
    return {
      loginLoading: false,
      loginForm: {
        username: '',
        password: '',
        remember: true,
        error: false
      }
    }
  },
  methods:{
    onLogin(){
      this.loginLoading = true
      login(this.loginForm.username, this.loginForm.password, this.loginForm.remember).then(res => {
        const loginRes = res.data
        if (loginRes.Success) {
          setAuthorization({
            token: loginRes.Data.Token,
            expireAt: new Date(new Date().getTime() + loginRes.Data.ExpireAt * 60 * 1000)
          })
          this.$store.commit("account/setUser", loginRes.Data.User)
          this.loginForm.error = false

          if(!isEmpty(this.$route.query?.backurl))
            this.$router.push(this.$route.query.backurl)
          else
            this.$route.push({name: 'hotel_index'})
        } else {
          this.loginForm.error = this.$t('error.' + loginRes.Code)
        }
        this.loginLoading = false
      }).catch(error => {
        console.log(error)
        this.loginLoading = false
      })
    },
  }
}
</script>

<style scoped>
.login-form-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
</style>